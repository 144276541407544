/* @flow */

import { useState, useCallback, useEffect } from "react";
import { useBrowser } from "@awardit/react-use-browser";

type UseSessionStorageReturnValue = [string, ((value: string) => void)];

const readSessionStorage = (key: string, initial: string) => {
  return sessionStorage.getItem(key) || initial;
};

const useSessionStorage = (
  key: string,
  initial: string
): UseSessionStorageReturnValue => {
  const browser = useBrowser();
  const [item, setInnerValue] = useState(initial);

  useEffect(() => {
    if (browser) {
      setInnerValue(readSessionStorage(key, initial));
    }
  }, [browser, setInnerValue, initial, key]);

  const setValue = useCallback((value: string) => {
    setInnerValue(value);

    sessionStorage.setItem(key, String(value));
  }, [key, setInnerValue]);

  return [item, setValue];
};

export default useSessionStorage;

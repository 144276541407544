/* @flow */

import { useTranslate } from "@awardit/react-use-translate";

type UseFormatReturnValue = {
  formatPrice: (price: number | string) => string,
};

// Finds groups of three digits in a number.
const MATCH_NUMBER_SET = /\B(?=(\d{3})+(?!\d))/g;

// Formats a number-as-string into 12 345.67 format.
const formatNumber = (n: string): string =>
  n.replace(".", ",").replace(MATCH_NUMBER_SET, " ");

const useFormat = (): UseFormatReturnValue => {
  const t = useTranslate();

  return {
    formatPrice: (price: number | string) =>
      // Max 2 decimals, and skip any trailing zeroes
      formatNumber(Number.parseFloat(String(price)).toFixed(2)) + " " + t("LOCALE.CURRENCY_SHORT"),
  };
};

export default useFormat;

module.exports = {
  host: "localhost",
  port: 9125,
  apiHost: {
    server: "http://127.0.0.1",
    client: "",
    headers: ["SSL", "Ssl-Offload", "X-Forward-Proto", "Host", "X-Real-Ip", "X-Forwarded-For", "Cookie"],
  },
  googleAnalytics: [
    { store: "se", currencyCode: "SEK", accounts: ["UA-160749998-2", "G-5T8RMTKS6B", "GTM-TNCD5MZL"] },
    { store: "dk", currencyCode: "DKK", accounts: ["UA-160749998-2", "G-5T8RMTKS6B", "GTM-TNCD5MZL"] },
    { store: "no", currencyCode: "NOK", accounts: ["UA-160749998-2", "G-5T8RMTKS6B", "GTM-TNCD5MZL"] },
    { store: "fi", currencyCode: "EUR", accounts: ["UA-160749998-2", "G-5T8RMTKS6B", "GTM-TNCD5MZL"] },
  ],
  isProduction: true,
  proxyHost: null,
  altapay: {
    src: "https://awardit.altapaysecure.com/checkout/v1/js/AltaPaySDK.js",
  },
  FACEBOOK_PIXEL_ID: 406814784318304,
  TIKTOK_PIXEL_ID: "CABJK83C77U7LMFBBUG0",
  datadog: {
    applicationId: 'd757b91c-822f-4cc4-b4ab-568b004d8f9d',
    clientToken: 'pub672a82e7d8ea2142a5558bb79fce04ac',
    site: 'datadoghq.eu',
    service:'zupergift',
    env:'production',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [/https:\/\/.*\.zupergift\.com/],
    cspUri: null,
  },
};

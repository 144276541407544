/* @flow */

import React, { createContext, useContext, useState } from "react";

type Props = {
  children: React$Node,
};

type SubNavOpenContextType = [boolean, ((boolean => boolean) | boolean) => void];

const SubNavOpenContext: React$Context<SubNavOpenContextType> =
  createContext<SubNavOpenContextType>([false, () => {}]);

const SubNavOpenProvider = ({ children }: Props): React$Node => {
  const value = useState(false);

  return (
    <SubNavOpenContext.Provider value={value}>
      {children}
    </SubNavOpenContext.Provider>
  );
};

const useSubNavOpen = (): SubNavOpenContextType => {
  const context = useContext(SubNavOpenContext);

  if (context === undefined) {
    throw new Error("useSubNavOpen must be used within a SubNavOpenProvider");
  }

  return context;
};

export {
  SubNavOpenProvider,
  useSubNavOpen,
};

/* @flow */

import React from "react";
import { Dialogue } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import useShowLanguageSwitcher from "./use-show-language-switcher";
import Button from "components/Button";
import CloseIcon from "icons/cross.svg";
import styles from "./styles.scss";

import DenmarkIcon from "icons/denmark.svg";
import FinlandIcon from "icons/finland.svg";
import NorwayIcon from "icons/norway.svg";
import SwedenIcon from "icons/sweden.svg";

const LanguageSwitcher = (): React$Node => {
  const t = useTranslate();
  const showLanguageSwitcher = useShowLanguageSwitcher();

  if (!showLanguageSwitcher.open) {
    return null;
  }

  return (
    <Dialogue
      open
      setOpen={showLanguageSwitcher.setOpen}
      className={styles.dialogue}
    >
      <div className={styles.block}>
        <header>
          <div className={styles.headerContent}>
            <h1>{t("LANGUAGE_SWITCHER.TITLE")}</h1>
            <p>{t("LANGUAGE_SWITCHER.SUB_TITLE")}</p>
          </div>
          <Button
            className={styles.close}
            onClick={() => showLanguageSwitcher.setOpen(false)}
          >
            <CloseIcon />
          </Button>
        </header>
        <div className={styles.body}>
          <a href="/dk"><DenmarkIcon /><span>{t("LANGUAGE_SWITCHER.DENMARK")}</span></a>
          <a href="/fi"><FinlandIcon /><span>{t("LANGUAGE_SWITCHER.FINLAND")}</span></a>
          <a href="/no"><NorwayIcon /><span>{t("LANGUAGE_SWITCHER.NORWAY")}</span></a>
          <a href="/se"><SwedenIcon /><span>{t("LANGUAGE_SWITCHER.SWEDEN")}</span></a>
        </div>
      </div>
    </Dialogue>
  );
};

export default LanguageSwitcher;

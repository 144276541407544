/* @flow */

import type { StatePath, Message } from "crustate";

/* eslint-disable import/no-unassigned-import */
import "core-js/features/symbol";
import "core-js/features/object/assign";
import "what-input";
/* eslint-enable import/no-unassigned-import */

// We need to initialize this early before we import any of the fetch wrappers
// or other resources to ensure RUM installs correctly:
import { datadogRum } from "@datadog/browser-rum";
import { datadog, googleAnalytics } from "../config";

const datadogIsEnabled = false;

if (datadogIsEnabled && datadog) {
  datadogRum.init({
    ...datadog,
    trackInteractions: true,
  });
}

import { StoreInfoContext, ClientProvider, createRunQuery } from "entrypoint/shared";
import { registerClient } from "../effects";

import React from "react";
import { hydrate } from "react-dom";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import fetch from "isomorphic-fetch";
import { HelmetProvider } from "react-helmet-async";
import { Storage } from "crustate";
import { StorageProvider } from "crustate/react";
import { createClient } from "@awardit/graphql-ast-client";
import { TranslationProvider } from "@awardit/react-use-translate";
import translation from "translation";
import Routes from "components/Routes";
import { AnalyticsProvider } from "context/analytics";
import {
  QuoteData, CustomerData, RouteData, HomeData, OrderData,
  MessagesData, BrandData, AllProductsData, SearchData, CmsData,
} from "../data";
import { markHydrated } from "@crossroads-loyalty-solutions/react-use-browser";

import "theme/_generic/_fonts.scss";
import "theme/_generic/_normalize.scss";
import "theme/_generic/_base.scss";
import "theme/_generic/_typography.scss";

const history = createBrowserHistory({ basename: "/" + window.shop.country });

const storage = new Storage();

if (window.snapshot) {
  storage.addModel(QuoteData.model);
  storage.addModel(CustomerData.model);
  storage.addModel(OrderData.model);
  storage.addModel(RouteData.model);
  storage.addModel(HomeData.model);
  storage.addModel(MessagesData.model);
  storage.addModel(BrandData.model);
  storage.addModel(AllProductsData.model);
  storage.addModel(SearchData.model);
  storage.addModel(CmsData.model);
  storage.restoreSnapshot(window.snapshot);

  delete window.snapshot;
}

const client = createClient({
  runQuery: createRunQuery(fetch, `/${window.shop.country}`),
  debounce: 5,
});

registerClient(storage, client, history);

window.storage = storage;
// $FlowIssue[method-unbinding]
window.getState = storage.getSnapshot.bind(storage);

const events = {
  // unhandledMessage: "warn",
  // stateCreated: "info",
  // stateRemoved: "info",
  // stateNewData: "info",
  // snapshotRestore: "info",
  // messageQueued: "info",
  messageMatched: "debug",
  // snapshotRestored: "debug",
};

Object.keys(events).forEach(eventName => {
  const level = events[eventName];
  storage.addListener((eventName: any), (...data) => console[level](eventName, ...data));
});

const root = document.getElementById("root");

if (!root) {
  throw new Error("Missing app root");
}

if (datadogIsEnabled && datadog) {
  storage.addListener("updateError", (
    e: mixed,
    stateData: mixed,
    statePath: StatePath,
    message: Message
  ) => datadogRum.addError(e, { statePath, message }));

  storage.addListener("unhandledMessage", (
    message: Message,
    statePath: StatePath
  ) => datadogRum.addError(new Error(`Unhandled message '${message.tag}' from ${statePath.join(", ")}`), { statePath, message }));
}

const currencyCode = window.shop.info.baseCurrencyCode;

// history.listen((...args) => console.log(JSON.parse(JSON.stringify(args))));

hydrate(
  <StoreInfoContext.Provider value={window.shop}>
    <ClientProvider value={client}>
      <HelmetProvider>
        <TranslationProvider translations={translation || {}}>
          <StorageProvider storage={storage}>
            <QuoteData.Provider>
              <CustomerData.Provider>
                <Router history={history}>
                  <AnalyticsProvider
                    currencyCode={currencyCode}
                    accounts={googleAnalytics
                      .filter(x => x.store === window.shop.country)
                      .filter(x => x.currencyCode === currencyCode)
                      .map(x => x.accounts)[0] || []
                    }
                  >
                    <Routes />
                  </AnalyticsProvider>
                </Router>
              </CustomerData.Provider>
            </QuoteData.Provider>
          </StorageProvider>
        </TranslationProvider>
      </HelmetProvider>
    </ClientProvider>
  </StoreInfoContext.Provider>,
  root,
  () => {
    // console.log(storage.getSnapshot());
    markHydrated();
  }
);

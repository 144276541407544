/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { Quote } from "shop-state/types";
import type { QuoteRequest, QuoteResponse } from "state/quote";

import { addMessage } from "@crossroads/shop-state/messages";
import { removeExampleEmail } from "helpers/utils";

import {
  QUOTE_INIT_REQUEST,
  QUOTE_INIT_RESPONSE,
  QUOTE_ADD_ITEM_REQUEST,
  QUOTE_ADD_ITEM_RESPONSE,
  QUOTE_REMOVE_ITEM_REQUEST,
  QUOTE_REMOVE_ITEM_RESPONSE,
  QUOTE_UPDATE_ITEM_REQUEST,
  QUOTE_UPDATE_ITEM_RESPONSE,
  QUOTE_SET_ADDRESSES_REQUEST,
  QUOTE_SET_ADDRESSES_RESPONSE,
  QUOTE_SET_EMAIL_REQUEST,
  QUOTE_SET_EMAIL_RESPONSE,
} from "@crossroads/shop-state/quote";

import {
  QUOTE_SET_DISCOUNT_CODE_REQUEST,
  QUOTE_SET_DISCOUNT_CODE_RESPONSE,
  QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
  QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE,
} from "state/quote";

import {
  quote as quoteQuery,
  addToCart,
  removeQuoteItem,
  updateQuoteItemQty as updateQuoteItemQtyQuery,
  setQuoteShippingAddress,
  setQuoteShippingMethodToCheapest,
  setQuoteBillingAddress,
  setQuoteBillingAddressAsShippingAddress,
  setQuoteDiscountCode as setQuoteDiscountCodeMutation,
  removeQuoteDiscountCode,
  setQuoteEmail,
} from "queries";

export const removeQuoteExampleEmail = <T: { quote: Quote }>(data: T): T => {
  if (data.quote) {
    removeExampleEmail(data.quote);
  }

  return data;
};

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: (msg: QuoteRequest, path) => {
      return client(quoteQuery)
        .then(removeQuoteExampleEmail)
        .then(({ quote: data }) => storage.replyMessage(({
          tag: QUOTE_INIT_RESPONSE,
          data,
        }: QuoteResponse), path));
    },
    subscribe: { [QUOTE_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest, path) => {
      if (msg.tag === QUOTE_ADD_ITEM_REQUEST) {
        const params = msg.bundleOptions ? {
          buyRequest: msg.buyRequest,
          qty: msg.qty,
          bundleOptions: msg.bundleOptions,
        } : {
          buyRequest: msg.buyRequest,
          qty: msg.qty,
        };

        try {
          const [{ addQuoteItem }] = await Promise.all([
            client(addToCart, params),
            client(setQuoteShippingMethodToCheapest),
          ]);

          if (addQuoteItem.result !== "success") {
            storage.broadcastMessage(addMessage(addQuoteItem.result, "error"));
          }
        }
        catch (e) {
          if (e.name === "QueryError" && Array.isArray(e.errors)) {
            e.errors.forEach(error => {
              if (error.extensions &&
                error.extensions.category === "retain24" &&
                error.extensions.code === "retain24_product_category_disallowed") {
                storage.broadcastMessage(addMessage("NOT_ALLOWED_TO_BUY_PRODUCT_WITH_CURRENT_GIFTCODE", "error"));
              }
            });
          }
        }

        const { quote } = await client(quoteQuery).then(removeQuoteExampleEmail);

        return storage.replyMessage(({
          tag: QUOTE_ADD_ITEM_RESPONSE,
          data: quote,
        }: QuoteResponse), path);
      }
    },
    subscribe: { [QUOTE_ADD_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest, path) => {
      if (msg.tag === QUOTE_UPDATE_ITEM_REQUEST) {
        try {
          const [{ updateQuoteItemQty }] = await Promise.all([
            client(updateQuoteItemQtyQuery, { itemBuyRequest: msg.itemBuyRequest, qty: msg.qty }),
            client(setQuoteShippingMethodToCheapest),
          ]);

          if (updateQuoteItemQty.result !== "success") {
            storage.broadcastMessage(addMessage(updateQuoteItemQty.result, "error"));
          }
        }
        catch (e) {
          if (e.name === "QueryError" && Array.isArray(e.errors)) {
            e.errors.forEach(error => {
              if (error.extensions &&
                error.extensions.category === "retain24" &&
                error.extensions.code === "retain24_product_category_disallowed") {
                storage.broadcastMessage(addMessage("NOT_ALLOWED_TO_BUY_PRODUCT_WITH_CURRENT_GIFTCODE", "error"));
              }
            });
          }
        }

        const { quote } = await client(quoteQuery).then(removeQuoteExampleEmail);

        return storage.replyMessage(({
          tag: QUOTE_UPDATE_ITEM_RESPONSE,
          data: quote,
        }: QuoteResponse), path);
      }
    },
    subscribe: { [QUOTE_UPDATE_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: QuoteRequest, path) => {
      if (msg.tag === QUOTE_REMOVE_ITEM_REQUEST) {
        client(removeQuoteItem, { itemBuyRequest: msg.itemBuyRequest });
        client(setQuoteShippingMethodToCheapest);

        return client(quoteQuery)
          .then(removeQuoteExampleEmail)
          .then(data => storage.replyMessage(({
            tag: QUOTE_REMOVE_ITEM_RESPONSE,
            data: data && data.quote,
          }: QuoteResponse), path));
      }
    },
    subscribe: { [QUOTE_REMOVE_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: QuoteRequest, path) => {
      if (msg.tag === QUOTE_SET_ADDRESSES_REQUEST) {
        client(setQuoteBillingAddress, { address: msg.billing });

        if (msg.shipToSameAddress) {
          client(setQuoteBillingAddressAsShippingAddress);
        }
        else {
          client(setQuoteShippingAddress, { address: msg.shipping });
        }

        client(setQuoteEmail, { email: msg.email });

        client(setQuoteShippingMethodToCheapest);

        return client(quoteQuery)
          .then(removeQuoteExampleEmail)
          .then(data => storage.replyMessage(({
            tag: QUOTE_SET_ADDRESSES_RESPONSE,
            data: data && data.quote,
          }: QuoteResponse), path));
      }
    },
    subscribe: { [QUOTE_SET_ADDRESSES_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_DISCOUNT_CODE_REQUEST) {
        const { setQuoteDiscountCode } = await client(setQuoteDiscountCodeMutation, {
          code: msg.code,
        });

        if (setQuoteDiscountCode.result !== "success") {
          storage.broadcastMessage(addMessage("DISCOUNT_CODE_FAILURE", "error"));
        }
        else {
          storage.broadcastMessage(addMessage("DISCOUNT_CODE_APPLIED", "success"));
        }

        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_SET_DISCOUNT_CODE_RESPONSE,
          data: (removeExampleEmail(data): Quote),
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_DISCOUNT_CODE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_REMOVE_DISCOUNT_CODE_REQUEST) {
        await client(removeQuoteDiscountCode);

        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE,
          data: (removeExampleEmail(data): Quote),
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_REMOVE_DISCOUNT_CODE_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: QuoteRequest, path) => {
      if (msg.tag === QUOTE_SET_EMAIL_REQUEST) {
        client(setQuoteEmail, { email: msg.email });

        return client(quoteQuery)
          .then(removeQuoteExampleEmail)
          .then(data => storage.replyMessage(({
            tag: QUOTE_SET_EMAIL_RESPONSE,
            data: data && data.quote,
          }: QuoteResponse), path));
      }
    },
    subscribe: { [QUOTE_SET_EMAIL_REQUEST]: true },
  });
};

export default registerClient;

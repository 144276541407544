/* @flow */

/*
  An image slider implementation
*/

import type { Image } from "components/BlurUpPicture";

import React, { useRef, useState, useEffect, useCallback } from "react";
import styles from "./styles.scss";
import cn from "classnames";

import BlurUpPicture from "components/BlurUpPicture";
import Button from "components/Button";

type Slide = {
  +heading?: string,
  +description?: string,
  +button: {
    +text?: string,
    +link?: string,
    +externalLink?: string,
  },
  +images: Array<Image>,
};

type Props = {
  className?: string,
  // @TODO: add type for slide
  slides: Array<Slide>,
};

type SlideProps = {
  className?: string,
  data: Slide,
  isMoving: boolean,
  isCurrent: boolean,
  advance: () => void,
  style: {},
};

const SliderItem = ({
  isCurrent,
  data,
  isMoving,
  advance,
  style,
  className }: SlideProps): React$Node => {
  const handleTransitionEnd = useCallback((e: Event) => {
    e.stopPropagation();
    if (isMoving) {
      advance();
    }
  }, [isMoving, advance]);

  const bar = useRef(null);
  const media = useRef(null);

  return (
    <li
      style={style}
      className={cn(
        className,
        styles.item
      )}
    >
      <div className={styles.body}>
        <div
          className={cn(
            styles.text,
            {
              [styles.isHidden]: isMoving,
              [styles.isCurrent]: isCurrent,
            }
          )}
        >

          {data.description !== undefined &&
            <p className={cn(styles.description, styles.row, "row")}>
              {data.description}
            </p>
          }
          {data.heading !== undefined &&
            <h1 className={cn(styles.heading, styles.row, "row")}>
              {data.heading}
            </h1>
          }

          {data.button.text && data.button.link &&
            <div className={cn(styles.row, "row")}>
              <Button
                to={data.button.link}
                variant="negative"
                className={styles.button}
              >
                {data.button.text || ""}
              </Button>
            </div>
          }

          {data.button.text && data.button.externalLink &&
            <div className={cn(styles.row, "row")}>
              <Button
                elem="a"
                href={data.button.externalLink}
                variant="negative"
                className={styles.button}
              >
                {data.button.text || ""}
              </Button>
            </div>
          }
        </div>

        <div
          ref={bar}
          className={styles.bar}
          style={{
            transform: media.current && bar.current && isMoving ?
              `translateX(-${media.current.offsetWidth + bar.current.offsetWidth}px)` :
              "translateX(0)",
          }}
          onTransitionEnd={handleTransitionEnd}
        />
      </div>

      <div
        ref={media}
        className={cn(
          styles.media,
          { [styles.isMoving]: !isCurrent && isMoving }
        )}
      >

        <BlurUpPicture
          className={styles.image}
          images={data.images}
        />
      </div>
    </li>
  );
};

const Slider = ({ slides, className }: Props): React$Node => {
  const [current, setCurrent] = useState(0);
  const [next, _setNext] = useState<number>(0);
  const [moving, setMoving] = useState(false);
  const list = useRef();
  const interval = useRef<IntervalID | null>(null);

  const setNotMoving = (e: Event) => {
    if (e.target === list.current) {
      setMoving(false);
    }
  };

  const stopAutoplay = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  }, [interval]);

  const setNext = useCallback((index: number) => {
    if (index === current) {
      return;
    }

    stopAutoplay();

    _setNext(index >= slides.length ? 0 : index);
  }, [_setNext, slides, current, stopAutoplay]);

  const startAutoplay = useCallback(() => {
    interval.current = setInterval(() => {
      setNext(current + 1);
    }, 6000);
  }, [interval, current, setNext]);

  const advance = () => {
    setCurrent(next % slides.length);
    setMoving(true);

    startAutoplay();
  };

  useEffect(() => {
    if (slides.length > 1) {
      startAutoplay();
    }

    return () => {
      stopAutoplay();
    };
  }, [slides, startAutoplay, stopAutoplay]);

  return (
    <section
      className={cn(
        className,
        styles.slider
      )}
    >
      <ul
        ref={list}
        className={cn(
          styles.list,
          { [styles.isMoving]: moving }
        )}
        style={{
          width: `${100 * slides.length}%`,
          transform: `translateX(-${(100 / slides.length) * current}%)`,
        }}
        onTransitionEnd={setNotMoving}
      >

        {slides.map((slide, i) => (
          <SliderItem
            key={i}
            data={slide}
            isMoving={current === i && next !== i}
            isCurrent={current === i}
            advance={advance}
            style={{
              width: `${100 / slides.length}%`,
            }}
          />
        ))}
      </ul>

      {slides.length > 1 &&
        <ul className={styles.dots}>
          {slides.map((slide, i) => (
            <li key={i} className={styles.dot}>
              <button
                type="button"
                disabled={moving}
                className={cn(
                  styles.dotButton,
                  { [styles.isActive]: next === i }
                )}
                onClick={() => setNext(i)}
              >
                &nbsp;
              </button>
            </li>
          ))}
        </ul>
      }
    </section>
  );
};

export default Slider;

/* @flow */

import React, { useState, useEffect } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { useHistory, useLocation } from "react-router-dom";
import Wrapper from "components/Wrapper";
import RedeemCard from "components/RedeemCard";
import PopularBrands from "components/PopularBrands";
import { QuoteData } from "data";
import { useData, useSendMessage } from "crustate/react";
import { addMessage } from "@crossroads/shop-state/messages";
import { Redirect } from "react-router";
import useSessionStorage from "helpers/use-session-storage";
import { parseParams } from "helpers/location-search-string";

import styles from "./styles.scss";

const RedeemView = (): React$Node => {
  const t = useTranslate();
  const quoteData = useData(QuoteData);
  const sendMessage = useSendMessage();
  const { search } = useLocation();
  const { returnUrl: returnUrlParam } = parseParams(search);
  const returnUrl = typeof returnUrlParam === "string" ? returnUrlParam : t("REDEEM.SUCCESS_ROUTE");
  const [alreadyActivedValidation, setAlreadyActivedValidation] = useState<?boolean>(null);

  useEffect(() => {
    if (quoteData.state === "LOADED" && alreadyActivedValidation === null) {
      setAlreadyActivedValidation(Boolean(quoteData.data.retain24validation));
    }
  }, [quoteData.state]);

  if (alreadyActivedValidation === true) {
    sendMessage(addMessage("success", "success"));
    return <Redirect to={returnUrl} />;
  }

  return <RedeemViewReal returnUrl={returnUrl} />;
};

const RedeemViewReal = ({ returnUrl }: { returnUrl: string }) => {
  const t = useTranslate();
  const { push } = useHistory();
  const sendMessage = useSendMessage();
  const [isolationMode] = useSessionStorage("navIsolationMode", "false");
  const navIsolationMode = isolationMode === "true";

  const onSuccess = () => {
    sendMessage(addMessage("success", "success"));
    push(returnUrl);
  };

  return (
    <div className={styles.block}>
      <Wrapper variant="thin">
        <h1>{t("REDEEM.HEADING")}</h1>

        <p>{t("REDEEM.TEXT")}</p>

        <RedeemCard className={styles.card} onSuccess={onSuccess} />

      </Wrapper>

      {!navIsolationMode &&
        <Wrapper>
          <PopularBrands />
        </Wrapper>
      }
    </div>
  );
};

export default RedeemView;

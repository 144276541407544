/* @flow */

import type { Location, RouterHistory as History } from "react-router";
import type { Category, HintCategory } from "shop-state/types";

import React, { useState } from "react";
import { useBrowser } from "@awardit/react-use-browser";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import styles from "./styles.scss";
import Wrapper from "components/Wrapper";
import ProductList, { ListItem, ListItemDummy } from "components/ProductList";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import Breadcrumbs from "components/Breadcrumbs";
import { InfiniteScroll, Pagination, Button } from "@crossroads/ui-components";
import { parseParams } from "helpers/location-search-string";
import useSessionStorage from "helpers/use-session-storage";
import { getCategoryMeta } from "helpers/get-meta";
import { useTranslate } from "@awardit/react-use-translate";
// Import useDevice from "helpers/use-device";

type CategoryProps = {
  category: Category,
  location: Location,
  history: History,
};

type HintCategoryProps = {
  category: HintCategory,
};

type HeroImageSource = {
  url: {
    x1: string,
    x2: string,
  },
  media: ?string,
};

const PaginationButton = ({ children, page }: { children: React$Node, page: number }) => {
  const location = useLocation();

  return <a href={`${location.pathname}?page=${page}`}>{children}</a>;
};

// Const MQ_FOUR = parseInt(styles.mqFour, 10);
// const MQ_THREE = parseInt(styles.mqThree, 10);

const prepareHeroImages = (c: Category): $ReadOnlyArray<HeroImageSource> => {
  const images = [];

  if (c.heroImageS) {
    images.push({
      url: {
        x1: c.heroImageS.x1,
        x2: c.heroImageS.x2,
      },
      media: "",
    });
  }

  if (c.heroImageM) {
    images.push({
      url: {
        x1: c.heroImageM.x1,
        x2: c.heroImageM.x2,
      },
      media: images.length > 0 ? `(min-width: ${styles.tiny}px)` : "",
    });
  }

  if (c.heroImageL) {
    images.push({
      url: {
        x1: c.heroImageL.x1,
        x2: c.heroImageL.x2,
      },
      media: images.length > 0 ? `(min-width: ${styles.small}px)` : "",
    });
  }

  return images.reverse();
};

const Hero = ({ category }: { category: Category }) => {
  const images = prepareHeroImages(category);

  // Show if category has "Category image large" in Magento, fallback to "Image"
  return images && images.length > 0 ? (
    <div className={styles.hero}>
      <div className={styles.text}>
        <h1 className={styles.title}>{category.name}</h1>
        {category.description &&
          <div
            dangerouslySetInnerHTML={{ __html: category.description }}
            className={styles.description}
          />
        }
      </div>
      <div className={styles.heroImage}>
        <picture>
          {images.map((image, i) => (
            <source
              key={i}
              srcSet={`${image.url.x1} 1x, ${image.url.x2} 2x`}
              media={image.media}
            />
          ))}

          <img src={images[0].url.x1} alt={category.name} />
        </picture>
      </div>
    </div>
  ) : (
    <div className={styles.imageWrapper}>
      {category.image &&
        <picture>
          <source srcSet={`${category.image.x1} 1x, ${category.image.x2} 2x`} />
          <img src={category.image.x1} alt={category.name} />
        </picture>
      }
      <h1 className={styles.title}>{category.name}</h1>
    </div>
  );
};

// TODO: use Pagination instead on server
const CategoryView = ({ category, location, history }: CategoryProps): React$Node => {
  const browser = useBrowser();
  const t = useTranslate();
  const meta = getCategoryMeta(category);
  const [page, setPage] = useState(() => {
    const params = parseParams(location.search);
    return Math.max(typeof params.page === "string" ? Number.parseInt(params.page, 10) : 1, 1);
  });
  const [pages, setPages] = useState(Array.from({ length: page }, (_, i) => i));

  // TODO: Use custom hook for this to improve performance
  // const four = useDevice("gt", MQ_FOUR - 1);
  // const three = useDevice("gt", MQ_THREE - 1);
  // const perPage = four ? 52 : (three ? 51 : 52);
  // Just an easy way to disable the pagination for now
  const perPage = 1000;

  const numPages = Math.ceil(category.products.items.length / perPage);

  const slicedProducts = category.products.items.slice((page - 1) * perPage, page * perPage);

  const [isolationMode] = useSessionStorage("navIsolationMode", "false");
  const navIsolationMode = isolationMode === "true";
  const breadcrumbLinks = useBreadcrumbLinks({ current: category.name });
  const { pathname, search } = useLocation();

  return (
    <Wrapper>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      <Hero category={category} />

      {!navIsolationMode ? <Breadcrumbs current={category.name} /> : null}

      {category.products.items.length > 0 ?
        <div>
          {browser ?
            <div>
              <ProductList>
                <InfiniteScroll
                  Placeholder={ListItemDummy}
                  numItems={Math.min(category.products.totalCount, page * perPage)}
                  pages={pages}
                  setPages={setPages}
                  perPage={perPage}
                >
                  {[].concat(...pages.map(n =>
                    category.products.items.slice(n * perPage, (n + 1) * perPage)
                      .map((p, i) => (
                        <ListItem
                          key={"p" + p.sku}
                          idx={(n * perPage) + i}
                          product={p}
                          breadcrumbLinks={[...breadcrumbLinks, {
                            pathname,
                            search,
                            title: category.name,
                            hint: {
                              name: category.name,
                            },
                          }]}
                          listName={category.name}
                        />
                      )
                      )))}
                </InfiniteScroll>
              </ProductList>
              {page < numPages &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: "2em" }}>
                  <Button
                    variant="blank"
                    onClick={() => {
                      setPage(page + 1);
                      setTimeout(() => {
                        history.replace({ search: `?page=${page + 1}` });
                      });
                    }}
                  >
                    {t("CATEGORY.LOAD_MORE")}
                  </Button>
                </div>
              }
            </div> :
            <div>
              <ProductList products={slicedProducts} listName={category.name} />

              <Pagination
                currentPage={page}
                pageCount={numPages}
                ButtonComponent={PaginationButton}
                onChange={x => setPage(x)}
              />
            </div>
          }
        </div> :
        <p className={styles.empty}>{t("CATEGORY.EMPTY")}</p>
      }
    </Wrapper>
  );
};

export const HintCategoryView = ({ category }: HintCategoryProps): React$Node => {
  const [isolationMode] = useSessionStorage("navIsolationMode", "false");
  const navIsolationMode = isolationMode === "true";

  return (
    <Wrapper>
      {!navIsolationMode ? <Breadcrumbs current={category.name} /> : null}

      <div className={styles.imageWrapper}>
        {category.image &&
          <picture>
            <source srcSet={`${category.image.x1} 1x, ${category.image.x2} 2x`} />
            <img src={category.image.x1} alt={category.name} />
          </picture>
        }
        <h1 className={styles.title}>{category.name}</h1>
      </div>

      <ProductList products={[null, null, null, null, null, null, null, null]} />
    </Wrapper>
  );
};

export default CategoryView;

/* @flow */

import { TIKTOK_PIXEL_ID } from "../config";

const event = (name: string, options: any) => {
  if (window.ttq) {
    window.ttq.track(name, options);
  }
};

const init = () => {
  if (window.ttq) {
    // Grant consent
    window.ttq.enableCookie();
    // Initialize pixel
    window.ttq.load(TIKTOK_PIXEL_ID);
    window.ttq.track("ViewContent");
  }
};

const ttq = {
  event,
  init,
};

export default ttq;


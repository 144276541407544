/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { HomeResponse } from "state/home";

import { HOME_REQUEST, HOME_RESPONSE } from "state/home";
import { home } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const data = await client(home, undefined, { cache: true });

      return ({
        tag: HOME_RESPONSE,
        data,
      }: HomeResponse);
    },
    subscribe: { [HOME_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { Link } from "react-router-dom";
import styles from "./styles.scss";

const Tutorial = (): React$Node => {
  const t = useTranslate();

  const steps = [
    {
      linkEnd: t("TUTORIAL.STEP_1.LINK_END"),
      title: t("TUTORIAL.STEP_1.TITLE"),
      text: t("TUTORIAL.STEP_1.TEXT"),
      imgSrc: "/assets/tutorial/1.png",
      link: t("TUTORIAL.STEP_1.LINK"),
    },
    {
      title: t("TUTORIAL.STEP_2.TITLE"),
      text: t("TUTORIAL.STEP_2.TEXT"),
      imgSrc: "/assets/tutorial/2.png",
    },
    {
      linkEnd: t("TUTORIAL.STEP_3.LINK_END"),
      title: t("TUTORIAL.STEP_3.TITLE"),
      text: t("TUTORIAL.STEP_3.TEXT"),
      imgSrc: "/assets/tutorial/3.png",
      link: t("TUTORIAL.STEP_3.LINK"),
    },
    {
      linkEnd: t("TUTORIAL.STEP_4.LINK_END"),
      title: t("TUTORIAL.STEP_4.TITLE"),
      text: t("TUTORIAL.STEP_4.TEXT"),
      imgSrc: "/assets/tutorial/4.svg",
      link: t("TUTORIAL.STEP_4.LINK"),
    },
  ];

  return (
    <div className={styles.block}>
      {steps.map(x => (
        <div key={x.title} className={styles.column}>
          <div className={styles.imgBackground}>
            <img src={x.imgSrc} />
          </div>
          <div className={styles.step}>
            <p className={styles.text}>
              {x.title}.{" "}{x.text}{" "}{(x.link && x.linkEnd) &&
              <Link className={styles.stepLink} to={x.link}>{x.linkEnd}</Link>}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tutorial;

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import bindHomeEffects from "./home";
import bindCustomerEffects from "./customer";
import bindQuoteEffects from "./quote";
import bindRouteEffects from "./route";
import bindOrderEffects from "./order";
import bindBrandEffects from "./brand";
import bindAllProductsEffects from "./all-products";
import bindSearchEffects from "./search";
import bindCmsEffects from "./cms";

type History = {
  push: (path: string) => void,
};

export const registerClient = (storage: Storage, client: Client<{}>, history: History) => {
  bindRouteEffects(storage, client);
  bindHomeEffects(storage, client);
  bindCustomerEffects(storage, client, history);
  bindQuoteEffects(storage, client);
  bindOrderEffects(storage, client);
  bindBrandEffects(storage, client);
  bindAllProductsEffects(storage, client);
  bindSearchEffects(storage, client, history);
  bindCmsEffects(storage, client);
};

/* @flow */

import type { ViewMode } from "shop-state/types";

import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import cn from "classnames";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Link, NavLink, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { QuoteData } from "data";
import { useData } from "crustate/react";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import CartCounter from "components/AppHeader/CartCounter";
import useSessionStorage from "helpers/use-session-storage";
import SystemMessages from "components/SystemMessages";
import CustomerBalance from "components/CustomerBalance";
import { collectorEnabledCountries } from "../Large";
import SearchMenu from "../SearchMenu";
import Drawer from "../Drawer";
import Logo from "../logo.svg";
import HamburgerIcon from "icons/hamburger.svg";
import CartIcon from "icons/cart.svg";
import CustomerServiceIcon from "icons/customer-service.svg";
import SearchIcon from "icons/search.svg";
import CloseIcon from "icons/cross.svg";

import { CartMini } from "@crossroads/ui-components";

import styles from "./styles.scss";
import { useSubNavOpen } from "context/sub-nav-open";
import { useViewMode } from "context/view-mode";

type Props = {
  className: string,
  hamburgerOpen: boolean,
  setHamburgerOpen: boolean => void,
  onCheckout: boolean,
  useBannerStyling: boolean,
};

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

const AppHeaderSmall = ({
  className = "",
  hamburgerOpen,
  setHamburgerOpen,
  onCheckout,
  useBannerStyling,
}: Props): React$Node => {
  const location = useLocation();
  const [isOpening, setIsOpening] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isolationMode] = useSessionStorage("navIsolationMode", "false");
  const navIsolationMode = isolationMode === "true";
  const drawerNode = React.createRef();
  const offCanvasNode = React.createRef();
  const [subNavOpen, setSubNavOpen] = useSubNavOpen();
  const [viewMode, setViewMode] = useViewMode();

  const showHeader = true;

  const openHamburger = () => {
    setIsOpening(true);
    setHamburgerOpen(true);

    setTimeout(() => {
      setIsOpening(false);
    }, Number.parseInt(styles.animationDuration, 10));
  };

  const t = useTranslate();

  const closeHamburger = () => {
    setIsClosing(true);

    setTimeout(() => {
      setSubNavOpen(false);
      setHamburgerOpen(false);
      setIsClosing(false);
      setViewMode("normal");
    }, Number.parseInt(styles.animationDuration, 10));
  };

  useEffect(() => {
    if (viewMode === "normal" || viewMode === "cart") {
      setHamburgerOpen(false);
    }
  }, [viewMode]);

  useIsomorphicLayoutEffect(() => {
    if (!hamburgerOpen) {
      enableBodyScroll(offCanvasNode.current);
      enableBodyScroll(drawerNode.current);
    }
    else {
      disableBodyScroll(offCanvasNode.current);
      disableBodyScroll(drawerNode.current);
    }
  }, [hamburgerOpen]);

  const { categories, info: { locale } } = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const activeRedeem = quoteData.data && quoteData.data.retain24validation;
  const showBuyLink = collectorEnabledCountries.includes(locale);

  const toggleCart = () => setViewMode(viewMode === "cart" ? "normal" : "cart");

  const setModeAndOpenMenu = (mode: ViewMode) => {
    setViewMode(mode);
    setSubNavOpen(true);
  };

  const setModeAndCloseHamburger = (mode: ViewMode) => {
    setViewMode(mode);
    setHamburgerOpen(false);
  };

  return (
    <div className={styles.outerContainer}>
      <div className={cn(
        styles.container,
        styles["mode__" + viewMode],
        { [styles.hamburgerOpen]: hamburgerOpen },
        { [styles.isOpening]: isOpening },
        { [styles.isClosing]: isClosing },
        { [styles.subNavOpen]: subNavOpen },
        { [styles.addHeight]: useBannerStyling }
      )}
      >
        <div className={cn(styles.block, className)}>
          <Wrapper className={cn(styles.wrapper)}>
            <div className={styles.left}>
              {navIsolationMode ? (
                <div className={styles.padding}>
                  <Logo
                    alt={t("LOGO.ALT")}
                    title={t("LOGO.TITLE")}
                    className={styles.logo}
                  />
                </div>
              ) : (
                <Link className={cn(styles.item, styles.padding)} to="/">
                  <Logo
                    alt={t("LOGO.ALT")}
                    title={t("LOGO.TITLE")}
                    className={styles.logo}
                  />
                </Link>
              )}
            </div>

            <div className={styles.right}>
              <nav className={styles.nav}>
                <div className={styles.nav}>
                  {!onCheckout &&
                  <NavLink
                    className={cn(styles.item, styles.padding)}
                    activeClassName={styles.active}
                    title={t("HEADER.HELP")}
                    to="/faq"
                  >
                    <CustomerServiceIcon />
                  </NavLink>
                  }

                  {!onCheckout && !navIsolationMode &&
                    <span
                      className={cn(
                        styles.item,
                        { [styles.active]: viewMode === "search" },
                        styles.padding
                      )}
                      onClick={() => {
                        setViewMode(viewMode === "search" ? "normal" : "search");
                      }}
                    >
                      <SearchIcon />
                    </span>
                  }

                  <CartCounter className={cn(styles.item, styles.padding)} />

                  {!navIsolationMode && !onCheckout &&
                    <span
                      className={cn(styles.item, styles.padding)}
                      onClick={() => hamburgerOpen ? closeHamburger() : openHamburger()}
                    >
                      <HamburgerIcon />
                    </span>
                  }
                </div>
              </nav>
            </div>
          </Wrapper>
        </div>

        <nav ref={offCanvasNode} className={styles.drawer}>
          <Wrapper className={styles.drawerMain}>
            <header className={styles.top}>
              <Logo
                alt={t("LOGO.ALT")}
                title={t("LOGO.TITLE")}
                className={styles.logo}
              />

              <Button onClick={closeHamburger}>
                <CloseIcon />
              </Button>
            </header>

            <div className={styles.importantItems}>
              {!activeRedeem && showBuyLink &&
                <NavLink
                  className={styles.item}
                  activeClassName={styles.active}
                  to="/zupergift"
                >
                  {t("HEADER.BUY_ZUPERGIFT")}
                </NavLink>
              }

              {!activeRedeem &&
                <NavLink
                  className={styles.item}
                  activeClassName={styles.active}
                  to="/redeem"
                >
                  {t("HEADER.REDEEM_GIFTCARD")}
                </NavLink>
              }

              <NavLink
                className={styles.item}
                activeClassName={styles.active}
                to={t("ROUTES.ALL_GIFTCARDS")}
              >
                {t("HEADER.ALL_GIFTCARDS")}
              </NavLink>

              <span
                className={styles.item}
                onClick={() => setModeAndOpenMenu("categories")}
              >
                {t("HEADER.CATEGORIES")}
              </span>

              <NavHashLink
                smooth
                className={cn(
                  styles.item,
                  { [styles.active]: process.browser && (location.pathname + location.hash === "/#about") }
                )}
                to="/#about"
                location={{ pathname: location.pathname + location.hash }}
                onClick={() => setModeAndCloseHamburger("normal")}
              >
                {t("HEADER.ABOUT")}
              </NavHashLink>
            </div>

            <NavLink
              className={styles.item}
              activeClassName={styles.active}
              to="/faq"
            >&nbsp;<CustomerServiceIcon />
              <span>{t("HEADER.HELP")}</span>
            </NavLink>

            <span
              className={styles.item}
              onClick={() => setModeAndCloseHamburger("search")}
            >&nbsp;<SearchIcon />
              <span>{t("HEADER.SEARCH")}</span>
            </span>

            <Link
              className={styles.item}
              title={t("HEADER.CART")}
              to={t("URL.CHECKOUT_CART")}
            >&nbsp;<CartIcon />
              <span>{t("HEADER.CART")}</span>
            </Link>

          </Wrapper>
          <Wrapper className={styles.bottom}>
            <CustomerBalance />
          </Wrapper>
        </nav>

        {viewMode === "search" &&
          <nav className={styles.search}>
            <SearchMenu
              heading={t("HEADER.SEARCH")}
              onClose={closeHamburger}
              onBack={() => setSubNavOpen(false)}
            />
          </nav>
        }

        <nav className={styles.categories}>
          <Drawer
            ref={drawerNode}
            setHamburgerOpen={setHamburgerOpen}
            heading={t("HEADER.CATEGORIES")}
            closeText={t("HEADER.CLOSE_CATEGORIES")}
            items={categories}
            onClose={closeHamburger}
            onItemClick={() => setViewMode("normal")}
            onBack={() => setSubNavOpen(false)}
          />
        </nav>

        {viewMode === "cart" &&
          <Wrapper>
            <CartMini
              ctaUrl={t("URL.CHECKOUT_CART")}
              variant="zupergift"
              showHeader={showHeader}
              quoteData={quoteData}
              onClose={toggleCart}
            />
          </Wrapper>
        }
      </div>

      {((hamburgerOpen || isClosing) || viewMode === "cart") &&
        <div
          className={cn(styles.dim, { [styles.isClosing]: isClosing })} onClick={() => {
            setViewMode("normal");
            closeHamburger();
          }} />
      }

      {((hamburgerOpen || isClosing) || viewMode === "search") &&
        <div
          className={cn(
            styles.dimSearch,
            {
              [styles.isClosing]: isClosing,
              [styles.bannerStyling]: useBannerStyling,
            })
          }
          onClick={() => {
            setViewMode("normal");
            closeHamburger();
          }}
        />
      }

      <Wrapper>
        <SystemMessages />
      </Wrapper>
    </div>
  );
};

export default AppHeaderSmall;

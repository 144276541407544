/* @flow */

import type { LocationShape } from "react-router-dom";
import type { ProductSubset } from "shop-state/types";

import React from "react";
import { useAnalytics } from "context/analytics";
import { Link } from "react-router-dom";

type ProductLinkProps = {
  product: ProductSubset,
  brand?: string,
  list?: string,
  position?: number,
  // Attributes passed to <Link />
  onClick?: (event: MouseEvent) => void,
  target?: string,
  /* eslint-disable react/boolean-prop-naming */
  replace?: boolean,
  /* eslint-enable react/boolean-prop-naming */
  to: string | LocationShape,
  innerRef?: { current: null | Element } | (el: Element) => void,
  children: React$Node,
};

/**
 * <Link /> replacement notifying google analytics about the clicked product,
 * accepts all of <Link />'s props
 * in addition to the ones listed on this class.
 */
const ProductLink = (props: ProductLinkProps): React$Node => {
  const analytics = useAnalytics();
  // Exclude <ProductLink /> specific attributes
  const { product, list, position, ...linkProps } = props;

  const onClickLink = (e: MouseEvent) => {
    analytics.registerProductClick(product, list, position);

    if (props.onClick !== undefined) {
      props.onClick(e);
    }
  };

  return <Link {...linkProps} onClick={onClickLink}>{props.children}</Link>;
};

export default ProductLink;

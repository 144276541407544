/* @flow */

import type { SearchResult } from "shop-state/types";

import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useData } from "crustate/react";
import { SearchData } from "data";
import styles from "./styles.scss";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import Breadcrumbs from "components/Breadcrumbs";
import { useTranslate } from "@awardit/react-use-translate";

type SearchProps = {
  data: SearchResult,
};

const SearchViewWrapper = (): React$Node => {
  const search = useData(SearchData);

  if (search.state !== "LOADED") {
    return null;
  }

  return <SearchView data={search.data} />;
};

const SearchView = ({ data }: SearchProps) => {
  const t = useTranslate();
  const location = useLocation();
  const query = location.pathname.split("/").slice(-1).pop();

  if (data.items.length === 0) {
    return (
      <Wrapper>
        <Breadcrumbs current={`${t("BREADCRUMBS.SEARCH_RESULTS_FOR")} "${query}"`} />

        <p className={styles.empty}>{t("CATEGORY.EMPTY")}</p>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Helmet
        title={`${t("SEARCH.TITLE_PREFIX")}${query}`}
      />

      <Breadcrumbs current={`${t("BREADCRUMBS.SEARCH_RESULTS_FOR")} "${query}"`} />

      <h1>
        <span className={styles.titlePrefix}>
          {data.totalCount > 1 ?
            t("SEARCH.PRODUCTS_IN_SEARCH_RESULT", { itemsCount: data.totalCount }) :
            t("SEARCH.PRODUCT_IN_SEARCH_RESULT")
          }

        </span> &quot;{query}&quot;
      </h1>

      <ProductList
        products={data.items} breadcrumbLink={{ search: query }}
        breadcrumbLinks={[{
          pathname: location.pathname,
          search: location.search,
          title: `${t("BREADCRUMBS.SEARCH_RESULTS_FOR")} "${query}"`,
          hint: {
            name: `${t("BREADCRUMBS.SEARCH_RESULTS_FOR")} "${query}"`,
          },
        }]}
        listName={`${t("SEARCH.TITLE_PREFIX")}${query}`}
      />
    </Wrapper>
  );
};

export default SearchViewWrapper;

/* @flow */

import type { OptionProduct } from "shop-state/types";
import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";
import { Button } from "@crossroads/ui-components";

type Props = {
  items: Array<{
    key?: string,
    buyRequest: ?string,
    product: OptionProduct,
    label: string,
    price?: {
      incVat: number,
    },
  }>,
  value: ?string,
  onChange: (o: any) => void,
};

const labelToNumber = (s: string) =>
  Number.parseInt(s.replace(/\D/, "").trim(), 10);

export const CardValueSelect = ({ items, value, onChange }: Props): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.cardValue}>
      {items
        .sort((a, b) =>
          labelToNumber(a.label) > labelToNumber(b.label) ? 1 : -1
        )
        .map(item => (
          <div key={item.buyRequest} className={styles.col}>
            <Button
              className={cn(
                styles.button,
                { [styles.active]: value === item.buyRequest },
                { [styles.outOfStock]: item.buyRequest === null }
              )}
              value={item.buyRequest}
              disabled={item.buyRequest === null}
              onClick={e => {
                e.preventDefault();
                onChange(item.buyRequest);
              }}
            >
              <div className={styles.shadow} />
              {item.label}

              {item.buyRequest === null &&
                <div className={styles.outOfStockLabel}>
                  {t("PRODUCT.OUT_OF_STOCK")}
                </div>
              }
            </Button>
          </div>
        ))}
    </div>
  );
};

/* @flow */

import type { ProductCardProduct, GAProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { useState, useEffect, useRef } from "react";
import ProductLink from "components/ProductLink";
import { useHistory } from "react-router-dom";
import styles from "./styles.scss";
import cn from "classnames";
import useFormat from "helpers/use-format";
import useSessionStorage from "helpers/use-session-storage";
import { useTranslate } from "@awardit/react-use-translate";
import Pixel from "components/Pixel";
import { useAnalytics } from "context/analytics";

type PriceProps = {
  product: ProductCardProduct,
  className?: string,
};

type ProductCardProps = {
  product: ProductCardProduct,
  list?: string,
  position?: number,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

const Price = ({ product, className }: PriceProps) => {
  const { formatPrice } = useFormat();
  const t = useTranslate();
  return (
    <span className={cn(styles.price, className)}>{product.type === "configurable" || product.type === "bundle" ? t("PRODUCT.STARTING_AT") : null} {formatPrice(product.price.incVat)}</span>
  );
};

const useCurrentSrc = (ref: { current: ?HTMLImageElement }, defaultSrc: string): string => {
  const [image, setImage] = useState(defaultSrc);

  useEffect(() => {
    const { current } = ref;

    if (current && current.currentSrc) {
      setImage(current.currentSrc);
    }
  }, [ref]);

  return image;
};

const ProductCard =
  ({ product, list, position, breadcrumbLinks }: ProductCardProps): React$Node => {
    const history = useHistory();
    const gaRef: { current: null | Element } = useRef(null);
    const imageRef = useRef();
    // Default to smallest image
    const { tinyImage, smallImage, mediumImage, largeImage } = product.attributes;
    const defaultImage = tinyImage || smallImage || mediumImage || largeImage || { x1: "", x2: "" };
    const image = useCurrentSrc(imageRef, defaultImage.x1);
    const [isolationMode] = useSessionStorage("navIsolationMode", "false");
    const navIsolationMode = isolationMode === "true";
    const analytics = useAnalytics();

    const mapGaObject = (product: ProductCardProduct): GAProduct => {
      const gaObject = {
        item_id: product.sku, // eslint-disable-line camelcase
        item_name: product.name, // eslint-disable-line camelcase
        item_brand: product.attributes.manufacturer, // eslint-disable-line camelcase
        price: product.price.incVat,
        index: Number(position) + 1,
        item_list_name: list ?? "", // eslint-disable-line camelcase
      };
      if (product.categories === undefined || product.categories.length === 0) {
        return gaObject;
      }

      product.categories.forEach((c, i) => {
        if (i === 0) {
          gaObject.item_category = c.name; // eslint-disable-line camelcase
        }
        else {
          gaObject[`item_category${i + 1}`] = c.name;
        }
      });

      return gaObject;
    };

    useEffect(() => {
      const gaObject = mapGaObject(product);

      if (!gaRef.current) {
        return;
      }

      analytics.register(gaRef.current, gaObject);
    }, [gaRef]);

    return (
      <ProductLink
        className={styles.block}
        product={{ ...product, qty: 1 }}
        position={position}
        list={list}
        to={{
          pathname: product.url,
          state: {
            hint: {
              type: "product",
              product,
              image,
            },
            breadcrumbLinks,
            list,
            position: Number(position) + 1,
          },
        }}
        innerRef={gaRef}
      >
        <picture className={styles.imageWrapper}>
          {largeImage &&
            <source
              srcSet={`${largeImage.x1} 1x, ${largeImage.x2} 2x`}
              media={`(min-width: ${styles.large}px)`}
            />
          }
          {mediumImage &&
            <source
              srcSet={`${mediumImage.x1} 1x, ${mediumImage.x2} 2x`}
              media={`(min-width: ${styles.medium}px)`}
            />
          }
          {smallImage &&
            <source
              srcSet={`${smallImage.x1} 1x, ${smallImage.x2} 2x`}
              media={`(min-width: ${styles.small}px)`}
            />
          }
          {tinyImage &&
            <source
              srcSet={`${tinyImage.x1} 1x, ${tinyImage.x2} 2x`}
              media="(min-width: 0px)"
            />
          }

          <img
            ref={imageRef}
            alt={product.name}
            src={defaultImage.x2}
            className={styles.image}
            loading="lazy"
          />
          <Pixel className={styles.imagePixel} />

          <Price className={styles.priceLarge} product={product} />
        </picture>

        <div className={styles.body}>
          <div className={styles.bodyTop}>
            <span className={cn(styles.name, "h2")}>
              {product.name}
            </span>

            <Price className={styles.priceSmall} product={product} />

            <div
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
              className={styles.description}
            />
          </div>
          {!navIsolationMode ?
            <div className={styles.bodyBottom}>
              {product.categories.map(x => (
                <span
                  key={x.url}
                  className={styles.categories}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    history.push({
                      pathname: x.url,
                      state: { hint: { type: "category", category: x } },
                    });
                  }}
                >
                  {x.name}
                </span>
              ))}
            </div> :
            null
          }
        </div>
      </ProductLink>
    );
  };

export const DummyCard = (): React$Node => {
  return (
    <div className={cn(styles.block, styles.dummy)}>
      <Pixel className={styles.image} />

      <div className={styles.body}>
        <span className={styles.name}>&nbsp;</span>

        <p className={styles.brand}>&nbsp;</p>

        <div>
          <p className={styles.price}>&nbsp;</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

/* @flow */

import React from "react";
import { useLocation, useHistory } from "react-router";
import styles from "./styles.scss";
import cn from "classnames";
import CartIcon from "icons/cart.svg";
import { QuoteData } from "data";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";

type Props = {
  className: string,
};

const CartCounter = ({ className = "" }: Props): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const history = useHistory();
  const data = useData(QuoteData);
  const onCheckout = location.pathname === t("URL.CHECKOUT_CART");
  const cartCount = data.data ? data.data.items.reduce((a, { qty }) => a + qty, 0) : 0;

  if (cartCount > 0) {
    return (
      <span
        className={cn(
          "link",
          styles.block,
          { [styles.active]: onCheckout },
          className
        )}
        onClick={() => {
          if (onCheckout) {
            history.goBack();
          }
          else {
            history.push(t("URL.CHECKOUT_CART"));
          }
        }}
      >
        <CartIcon />
        <span className={cn(styles.count, { [styles.countSmall]: cartCount > 9 })}>
          {cartCount}
        </span>
      </span>
    );
  }

  return (
    <div className={cn(styles.block, className)}>
      <CartIcon />
    </div>
  );
};

export default CartCounter;

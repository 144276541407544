/* @flow */

import type { ViewMode } from "shop-state/types";

import React, { createContext, useState, useContext } from "react";

type Props = {
  children: React$Node,
};

type ViewModeContextType = [
  ViewMode,
  ((ViewMode => ViewMode) | ViewMode) => void,
];

const ViewModeContext: React$Context<ViewModeContextType> =
  createContext<ViewModeContextType>(["normal", () => {}]);

const ViewModeProvider = ({ children }: Props): React$Node => {
  const value = useState<ViewMode>("normal");

  return (
    <ViewModeContext.Provider value={value}>
      {children}
    </ViewModeContext.Provider>
  );
};

const useViewMode = (): ViewModeContextType => {
  const context = useContext(ViewModeContext);

  if (context === undefined) {
    throw new Error("useViewMode must be used within a ViewModeProvider");
  }

  return context;
};

export {
  ViewModeProvider,
  useViewMode,
};


/* @flow */

import type { ViewMode } from "shop-state/types";

import React from "react";
import cn from "classnames";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@crossroads/ui-components";
import styles from "./styles.scss";
import Wrapper from "components/Wrapper";
import CloseIcon from "icons/cross.svg";
import ChevronIcon from "icons/chevron.svg";

export type Item = {
  name: string,
  url: string,
};

type ParentItem = {
  name: string,
  url: string,
  children: Array<Item>,
};

type Props = {
  setViewMode?: ViewMode => void,
  setHamburgerOpen?: boolean => void,
  className?: string,
  onClose: () => void,
  onBack?: () => void,
  closeText: string,
  heading: string,
  items: Array<ParentItem>,
};

type ItemProps = {
  className: string,
  item: Item,
  children: React$Node,
};

const Drawer: React$AbstractComponent<Props, {}> = React.forwardRef<Props, {}>(({
  setViewMode,
  setHamburgerOpen,
  items,
  heading,
  closeText,
  onClose,
  className = "",
  onBack,
}: Props, ref: React$Ref<any>) => {
  const history = useHistory();
  const ItemLink = ({ item, className = styles.item, children }: ItemProps) => {
    const isParent = history.location.pathname === item.url;

    return item.url === "/" ? (
      <div className={className}>
        {children}
      </div>
    ) : (
      <Link
        to={{
          pathname: item.url,
          state: { hint: { type: "category", category: item } },
        }}
        className={className}
        onClick={() => {
          if (isParent) {
            if (setViewMode) {
              setViewMode("normal");
            }
            else if (setHamburgerOpen) {
              setHamburgerOpen(false);
            }
          }
        }}
      >
        {children}
      </Link>
    );
  };

  return (
    <div ref={ref} className={cn(styles.block, className)}>
      <div className={styles.row}>
        <Wrapper className={styles.top}>
          {onBack &&
            <Button className={styles.back} onClick={onBack}>
              <ChevronIcon />
            </Button>
          }
          <span className={styles.heading}>{heading}</span>
          <Button className={cn(styles.close, styles.actionButton)} onClick={onClose}>
            <span className={styles.closeText}>{closeText}</span>
            <CloseIcon />
          </Button>
        </Wrapper>
      </div>

      <Wrapper>
        <div className={styles.wrapper}>
          {items.map(x => (
            <ItemLink key={x.name} item={x} className={styles.item}>
              <div className={styles.item__heading}>{x.name}</div>
            </ItemLink>
          ))}
        </div>
      </Wrapper>
    </div>
  );
});

export default Drawer;

/* @flow */

import React from "react";
import { HashLink } from "react-router-hash-link";
import { useTranslate } from "@awardit/react-use-translate";
import ChevronIcon from "icons/chevron.svg";

import styles from "./styles.scss";

const NewsletterBanner = (): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.newsletterBanner}>
      <p className={styles.text}>{t("HEADER.BANNER.TEXT")}</p>
      <HashLink smooth to="#newsletter" className={styles.link}>{t("HEADER.BANNER.BUTTON")} <ChevronIcon className={styles.icon} /></HashLink>
    </div>
  );
};

export default NewsletterBanner;

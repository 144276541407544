/* @flow */

import type { SearchResult } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", query: string }
  | { state: "LOADED", data: SearchResult }
  | { state: "ERROR", error: string };

export type SearchResponse = Response<typeof SEARCH_RESPONSE, ?SearchResult>;

export type SearchRequest = {
  tag: typeof SEARCH_REQUEST,
  query: string,
};

export const SEARCH_RESPONSE: "response/search" = "response/search";
export const SEARCH_REQUEST: "request/search" = "request/search";

export const SearchModel: Model<Data, { query: string }, SearchRequest | SearchResponse> = {
  id: "search",
  init: ({ query }) =>
    updateData(
      { state: "LOADING", query },
      { tag: SEARCH_REQUEST, query }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case SEARCH_REQUEST:
        return updateData({ state: "LOADING", query: msg.query });
      case SEARCH_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { AllProductsRequest, AllProductsResponse } from "state/all-products";

import { ALL_PRODUCTS_REQUEST, ALL_PRODUCTS_RESPONSE } from "state/all-products";
import { rootCategory } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: (msg: AllProductsRequest, path) => {
      return client(rootCategory, undefined, { cache: true })
        .then(data => storage.replyMessage(({
          tag: ALL_PRODUCTS_RESPONSE,
          data: {
            description: data.rootCategory.description,
            items: data.rootCategory.products.items,
          },
        }: AllProductsResponse), path));
    },
    subscribe: { [ALL_PRODUCTS_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

type Error = {
  field: string,
  error: string,
};

declare type DOMEvent<T> = {
  target: T,
} & Event;

export const focusInvalidField = (e: SyntheticEvent<HTMLFormElement>, errors: Array<Error>) => {
  const field = e.currentTarget.querySelector(`[name='${errors[0].field}']`);

  if (field) {
    field.focus();
  }
};

export const loadScript = (isLoaded: () => boolean, src: string, cb: () => void): void => {
  if (!process.browser) {
    return;
  }

  if (isLoaded()) {
    cb();

    return;
  }

  const head = document.querySelector("head");
  const script = document.createElement("script");

  script.src = src;

  /* eslint-disable unicorn/prefer-add-event-listener */
  script.onload = () => {
    script.onload = null;

    cb();
  };
  /* eslint-enable unicorn/prefer-add-event-listener */

  if (head) {
    /* eslint-disable unicorn/prefer-dom-node-append */
    head.appendChild(script);
    /* eslint-enable unicorn/prefer-dom-node-append */
  }
};

export const removeExampleEmail = <T: { email: ?string }>(object: T): T => {
  if (object && object.email && /@example.com$/.test(object.email)) {
    return {
      ...object,
      email: null,
    };
  }

  return object;
};

export const formatDate = (d: string): string => {
  const date = new Date(d);

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
};

/* @flow */

import type { ProductCardProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import cn from "classnames";
import ProductCard, { DummyCard } from "components/ProductCard";
import { useVisibilityObservable } from "@crossroads/ui-components";
import styles from "./styles.scss";
import React, { memo } from "react";

type Props = {
  children?: React$Node,
  products?: $ReadOnlyArray<?ProductCardProduct>,
  listName?: string,
  heading?: string,
  productsPerRow?: 4 | 2,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

type ItemProps = {
  product: ProductCardProduct,
  idx?: number,
  listName?: string,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

type DummyItemProps = {
  idx?: number,
};

export const ListItem: React$AbstractComponent<ItemProps, any> = memo<ItemProps>(({
  product,
  idx,
  listName,
  breadcrumbLinks,
}: ItemProps) => {
  const el = useVisibilityObservable();

  return (
    <div ref={el} className={styles.item} data-idx={idx}>
      <div className={styles.itemInner}>
        <ProductCard
          product={product}
          list={listName}
          position={idx}
          breadcrumbLinks={breadcrumbLinks}
        />
      </div>
    </div>
  );
});

export const ListItemDummy: React$AbstractComponent<DummyItemProps, any> =
  memo<DummyItemProps>(({ idx }: DummyItemProps) => {
    const el = useVisibilityObservable();

    return (
      <div ref={el} className={styles.item} data-idx={idx}>
        <div className={styles.itemInner}>
          <DummyCard />
        </div>
      </div>
    );
  });

const ProductList = ({
  products,
  children,
  heading,
  productsPerRow = 4,
  listName,
  breadcrumbLinks,
}: Props): React$Node => {
  return (
    <div>
      {heading &&
        <h2>{heading}</h2>
      }
      <div className={cn(
        styles.block,
        [styles["block__perRow" + productsPerRow]]
      )}
      >
        {products ? products.map((x, i) => (
          x ?
            <ListItem
              key={x.sku}
              product={x}
              listName={listName}
              breadcrumbLinks={breadcrumbLinks}
              idx={i}
            /> :
            <ListItemDummy key={i} />
        )
        ) : children}
      </div>
    </div>
  );
};

export default ProductList;

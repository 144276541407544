/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { Customer } from "shop-state/types";
import type { CustomerRequest, CustomerResponse } from "@crossroads/shop-state/customer";

import { CUSTOMER_INIT_REQUEST, CUSTOMER_INIT_RESPONSE, CUSTOMER_LOGOUT_REQUEST, CUSTOMER_LOGOUT_RESPONSE } from "@crossroads/shop-state/customer";
import {
  customer,
  logout,
} from "queries";
import { removeExampleEmail } from "helpers/utils";

type History = {
  push: (path: string) => void,
};

const removeCustomerExampleEmail = <T: { customer: Customer }>(object: T): T => {
  if (object.customer) {
    removeExampleEmail(object.customer);
  }

  return object;
};

const registerClient = (storage: Storage, client: Client<{}>, history: History) => {
  storage.addEffect({
    effect: (msg: CustomerRequest, path) => {
      return client(customer)
        .then(removeCustomerExampleEmail)
        .then(data => storage.replyMessage(({
          tag: CUSTOMER_INIT_RESPONSE,
          data: data.customer,
        }: CustomerResponse), path));
    },
    subscribe: { [CUSTOMER_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: CustomerRequest, path) => {
      return client(logout)
        .then(data => {
          storage.replyMessage(({
            tag: CUSTOMER_LOGOUT_RESPONSE,
            data,
          }: CustomerResponse), path);

          history.push("/");
        });
    },
    subscribe: { [CUSTOMER_LOGOUT_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";
import { Breadcrumbs } from "@crossroads/ui-components";
import styles from "./styles.scss";

type Props = {
  className?: string,
  root?: ?{
    url: string,
    title?: string,
    icon?: React$ComponentType<any>,
  },
  current: string,
  links?: $ReadOnlyArray<BreadcrumbLink>,
};

const Slash = () => <span>&nbsp;/&nbsp;</span>;

const CustomBreadcrumbs = ({ className, ...props }: Props): React$Node => {
  return (
    <div className={cn(className, styles.block)}>
      <Breadcrumbs
        {...props}
        Delimeter={Slash}
      />
    </div>
  );
};

export default CustomBreadcrumbs;

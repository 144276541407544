/* @flow */

import React from "react";
import useFormat from "helpers/use-format";
import styles from "./styles.scss";

type Props = {
  price: number,
};

const Price = ({ price }: Props): React$Node => {
  const { formatPrice } = useFormat();

  return (
    <p className={styles.price}>
      {formatPrice(price)}
    </p>
  );
};

export default Price;

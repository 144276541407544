/* @flow */

import type { ProductCardProduct } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type AllProducts = { description: string, items: Array<ProductCardProduct> };

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: AllProducts }
  | { state: "ERROR", error: string };

export type AllProductsResponse =
  Response<typeof ALL_PRODUCTS_RESPONSE, ?AllProducts>;

export type AllProductsRequest = {
  tag: typeof ALL_PRODUCTS_REQUEST,
};

export const ALL_PRODUCTS_RESPONSE: "response/allProducts" = "response/allProducts";
export const ALL_PRODUCTS_REQUEST: "request/allProducts" = "request/allProducts";

export const AllProductsModel: Model<
  Data,
  {},
  AllProductsRequest | AllProductsResponse
> = {
  id: "allProducts",
  init: () =>
    updateData(
      { state: "LOADING" },
      { tag: ALL_PRODUCTS_REQUEST }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case ALL_PRODUCTS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

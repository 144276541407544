/* @flow */

import React, { useRef, useEffect } from "react";
import styles from "./styles.scss";
import { useHistory } from "react-router";
import cn from "classnames";
import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";
import { useAnalytics } from "context/analytics";

import SearchIcon from "icons/search.svg";

type Props = {
  className?: string,
};

const SearchMenu = ({ className = "" }: Props): React$Node => {
  const history = useHistory();
  const t = useTranslate();
  const inputRef = useRef(null);
  const analytics = useAnalytics();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const suggestions = [];

  const onSubmit = (e: Event) => {
    e.preventDefault();

    if (inputRef.current !== null) {
      const { value } = inputRef.current;

      if (!value || value.length <= 2) {
        return;
      }

      history.push(`/search/${value}`);
      analytics.searchTerm(value);
    }
  };

  return (
    <form className={cn(className, styles.block)} onSubmit={onSubmit}>
      <Wrapper className={styles.container}>
        <button type="submit" className={styles.submit}>
          <SearchIcon className={styles.icon} />
        </button>

        <input
          ref={inputRef}
          className={styles.input}
          type="text"
          placeholder={t("SEARCH.PLACEHOLDER")} />
      </Wrapper>

      <section className={styles.suggestions}>
        {suggestions.map(x => (
          <div key={x.id} className={styles.suggestion}>
            <Wrapper className={styles.suggestionWrapper}>
              <div className={styles.suggestionLeft}>
                <img src={x.image} alt={x.name} className={styles.suggestionImage} />

                <div className={styles.suggestionBody}>
                  <div>
                    <p className={styles.suggestionName}>{x.name}</p>
                    <p className={styles.suggestionBrand}>{x.brand}</p>
                  </div>
                </div>
              </div>

              <span className={styles.suggestionPrice}>{x.price}</span>
            </Wrapper>
          </div>
        ))}
      </section>
    </form>
  );
};

export default SearchMenu;

/* @flow */

import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Input, Foldable } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import ChevronIcon from "icons/chevron.svg";
import CloseIcon from "icons/cross.svg";
import useSubmitRedeem from "helpers/use-submit-redeem";
import { parseParams } from "helpers/location-search-string";
import useDevice from "helpers/use-device";
import { RECAPTCHA_KEY } from "entrypoint/shared";

import styles from "./styles.scss";

type Props = {
  onSuccess: () => void,
  variant?: "default" | "plain",
  isLocked?: boolean,
  className?: string,
  isDisabled?: boolean,
};

const RedeemCard = ({
  onSuccess,
  className,
  variant = "default",
  isLocked = false,
  isDisabled,
}: Props): React$Node => {
  const t = useTranslate();
  const { location } = useHistory();
  const [processing, setProcessing] = useState(false);
  const [input, setInput] = useState<{ code: string, pin: string }>({ code: "", pin: "" });
  const isDesktop = useDevice("gt", Number.parseInt(styles.tiny, 10));
  const { submit: submitRedeem, requiresPin, resetSubmit } = useSubmitRedeem();

  useEffect(() => {
    (async () => {
      const { code, pin } = parseParams(location.search);

      if (code && grecaptcha) {
        setProcessing(true);
        setInput({ code: String(code), pin: pin ? String(pin) : "" });

        grecaptcha.ready(async () => {
          const token = await grecaptcha.execute(RECAPTCHA_KEY, { action: "retain24validation" });

          const success = await submitRedeem({ code: String(code), pin: pin ? String(pin) : "" }, token);

          if (success === true) {
            onSuccess();
          }
          else {
            resetSubmit();
            setProcessing(false);
          }
        });
      }
    })();
  }, []);

  const submit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isLocked) {
      return;
    }

    if (!input.code || (requiresPin && !input.pin)) {
      return;
    }

    setProcessing(true);

    grecaptcha.ready(async () => {
      const token = await grecaptcha.execute(RECAPTCHA_KEY, { action: "retain24validation" });
      const success = await submitRedeem(input, token);

      if (success === true) {
        setInput({ code: "", pin: "" });
        onSuccess();
      }

      setProcessing(false);
    });
  };

  const reset = () => {
    setInput({ code: "", pin: "" });
    resetSubmit();
    setProcessing(false);
  };

  return (
    <form
      className={cn(
        { [styles.default]: variant === "default" },
        { [styles.plain]: variant === "plain" },
        className
      )}
      onSubmit={submit}
    >
      <div className={cn(
        styles.block,
        { [styles.defaultInnerFlipped]: requiresPin && variant !== "plain" },
        { [styles.defaultInner]: variant === "default" },
        { [styles.plainInner]: variant === "plain" }
      )}
      >
        <div className={styles.innerHeading}>{t("CHECKOUT.REDEEM_LABEL_CODE")}</div>
        <div className={cn(
          { [styles.defaultInnerFront]: variant === "default" },
          { [styles.plainInnerFront]: variant === "plain" },
          { [styles.plainInnerFrontActivePin]: requiresPin && variant === "plain" }
        )}
        >
          <Input
            className={styles.input}
            labelClassName={styles.label}
            value={input.code}
            type="text"
            disabled={isDisabled}
            variant="light"
            label={t("CHECKOUT.REDEEM_LABEL_CODE")}
            placeholder={t("REDEEM.PLACEHOLDER_CODE")}
            onChange={e => setInput({ ...input, code: e.target.value.replace(/\s+/g, "") })}
            onBlur={isDesktop ? submit : null}
          />
          <div className={cn(
            { [styles.requiresPin]: requiresPin },
            { [styles.defaultInnerFrontPin]: variant === "default" },
            { [styles.plainInnerFrontPin]: variant === "plain" }
          )}
          >
            <Foldable open={requiresPin}>
              <Input
                className={styles.input}
                labelClassName={styles.label}
                value={input.pin}
                type="text"
                variant="light"
                label={t("CHECKOUT.REDEEM_LABEL_PIN")}
                placeholder={t("REDEEM.PLACEHOLDER_PIN")}
                onChange={e => setInput({ ...input, pin: e.target.value.replace(/\s+/g, "") })}
                onBlur={isDesktop ? submit : null}
              />
            </Foldable>
          </div>
          <div className={styles.buttonWrapperCode}>
            <Button
              className={styles.buttonCode}
              variant="primary"
              disabled={input.code.length < 3}
              loading={processing}
            >
              <span>{t("CHECKOUT.REDEEM_SUBMIT")}</span>
              <ChevronIcon />
            </Button>
          </div>
        </div>
        <div className={cn(
          { [styles.defaultInnerBack]: variant === "default" },
          { [styles.plainInnerBack]: variant === "plain" }
        )}
        >
          <Input
            className={styles.input}
            labelClassName={styles.label}
            value={input.pin}
            type="text"
            variant="light"
            label={t("CHECKOUT.REDEEM_LABEL_PIN")}
            placeholder={t("REDEEM.PLACEHOLDER_PIN")}
            onChange={e => setInput({ ...input, pin: e.target.value.replace(/\s+/g, "") })}
            onBlur={submit}
          />
          <Button className={styles.close} onClick={reset}>
            <CloseIcon />
          </Button>
          <div className={styles.buttonWrapperPin}>
            <Button
              className={styles.buttonPin}
              variant="primary"
              loading={processing}
            >
              <span>{t("REDEEM.SUBMIT")}</span>
              <ChevronIcon />
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RedeemCard;

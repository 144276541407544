/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { SearchRequest, SearchResponse } from "state/search";

import { SEARCH_REQUEST, SEARCH_RESPONSE } from "state/search";
import { search } from "queries";

type History = {
  push: (path: string) => void,
};

const registerClient = (storage: Storage, client: Client<{}>, history: History) => {
  storage.addEffect({
    effect: (msg: SearchRequest, path) => {
      return client(search, { query: msg.query }, { cache: true })
        .then(data => storage.replyMessage(({
          tag: SEARCH_RESPONSE,
          data: data.productsBySearch,
        }: SearchResponse), path))
        .catch(() => {
          history.push("/");
        });
    },
    subscribe: { [SEARCH_REQUEST]: true },
  });
};

export default registerClient;

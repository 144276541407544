/* @flow */

const event = (action: string, name: string, options: any) => {
  if (window.tdconv) {
    window.tdconv(action, name, options);
  }
};

const init = (orgId: string) => {
  if (window.tdconv) {
    // Initialize tdconv
    window.tdconv("init", orgId, { element: "iframe" });
  }
};

const tdconv = {
  event,
  init,
};

export default tdconv;


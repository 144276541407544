/* @flow */

import React, { useState } from "react";

import Wrapper from "components/Wrapper";
import Button from "components/Button";
import { Input } from "@crossroads/ui-components";
import { subscribeToNewsletter } from "queries";
import { useClient } from "entrypoint/shared";
import { addMessage } from "@crossroads/shop-state/messages";
import { useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

const NewsletterBlock = (): React$Node => {
  const client = useClient();
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const [input, setInput] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);

  const submit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProcessing(true);

    const res = await client(subscribeToNewsletter, { email: input });

    if (res.subscribeToNewsletter.result !== "success") {
      setInput("");
      sendMessage(addMessage("NEWSLETTER.ERROR." + res.subscribeToNewsletter.result, "error"));
    }
    else {
      setInput("");
      sendMessage(addMessage("NEWSLETTER.SUCCESS", "success"));
    }

    setProcessing(false);
  };

  return (
    <div id="newsletter" className={styles.block}>
      <Wrapper>
        <h2 className={styles.heading}>
          {t("NEWSLETTERBANNER.HEADER")}
        </h2>
        <form className={styles.form} onSubmit={submit}>
          <Input
            className={styles.test}
            value={input}
            type="text"
            variant="light"
            placeholder={t("NEWSLETTERBANNER.FORM.PLACEHOLDER")}
            onChange={e => setInput(e.target.value)}
          />
          <Button
            className={styles.button}
            variant="primary"
            loading={processing}
          >
            {t("NEWSLETTERBANNER.FORM.BUTTON_TEXT")}
          </Button>
        </form>
        <p dangerouslySetInnerHTML={{ __html: t("NEWSLETTERBANNER.TERMS.TEXT") }} className={styles.termsText} />
      </Wrapper>
    </div>
  );
};

export default NewsletterBlock;

/* @flow */

import { useState } from "react";
import { addMessage, clearMessages } from "@crossroads/shop-state/messages";
import { useSendMessage } from "crustate/react";
import { syncQuote } from "@crossroads/shop-state/quote";
import { setQuoteRetain24Validation, setQuoteRetain24ValidationWithPin, quote as quoteQuery } from "queries";
import { useClient } from "entrypoint/shared";

type SubmitInput = { code: string, pin: string };

type UseSubmitRedeemReturnType = {
  requiresPin: boolean,
  resetSubmit: () => void,
  submit: (
    input: SubmitInput,
    recaptchaResponse: string
  ) => Promise<boolean>,
};

const useSubmitRedeem = (setSubmitting?: boolean => void): UseSubmitRedeemReturnType => {
  const client = useClient();
  const sendMessage = useSendMessage();
  const [requiresPin, setRequiresPin] = useState(false);

  return {
    resetSubmit: () => setRequiresPin(false),
    requiresPin,
    submit: async (input: SubmitInput, recaptchaResponse: string) => {
      let externalPin = input.pin;

      if (input.pin.length === 4) {
        externalPin = "0" + input.pin;
      }

      if (setSubmitting) {
        setSubmitting(true);
      }

      try {
        let result;
        if (externalPin) {
          const response = await client(setQuoteRetain24ValidationWithPin, {
            code: input.code,
            recaptchaResponse,
            ...(externalPin ? { pin: externalPin } : {}),
          });
          result = response.setQuoteRetain24Validation.result;
        }
        else {
          const response = await client(setQuoteRetain24Validation, {
            code: input.code,
            recaptchaResponse,
          });
          result = response.setQuoteRetain24Validation.result;
        }

        if (setSubmitting) {
          setSubmitting(false);
        }

        if (result === "success") {
          const newQuoteData = await client(quoteQuery);
          const newQuote = newQuoteData.quote;

          if (newQuote) {
            sendMessage(syncQuote(newQuote));
            return true;
          }

          // Reload page if new quote couldn't be fetched
          window.location.reload();
        }
        else if (result === "_791") {
          // Requires PIN
          setRequiresPin(true);
          return false;
        }
        else if (result === "_792") {
          // Invalid PIN
          sendMessage(addMessage("REDEEM_ERROR." + result, "error"));
          return false;
        }

        // Other error
        sendMessage(addMessage("REDEEM_ERROR." + result, "error"));
        setRequiresPin(false);
        return false;
      }
      catch (e) {
        let result = false;

        if (e.name === "QueryError" && Array.isArray(e.errors)) {
          result = await e.errors.reduce(async (acc, error) => {
            if (!error.extensions || error.extensions.category !== "retain24") {
              return acc;
            }

            switch (error.extensions.code) {
              case "retain24_product_category_disallowed":
                sendMessage(clearMessages());
                sendMessage(addMessage("NOT_ALLOWED_TO_USE_GIFTCODE_WITH_CURRENT_PRODUCTS", "error"));
                const newQuoteData = await client(quoteQuery);

                if (newQuoteData.quote) {
                  sendMessage(syncQuote(newQuoteData.quote));
                }

                return true;

              case "retain24_recaptcha_verification_failed":
                sendMessage(clearMessages());
                sendMessage(addMessage("REDEEM_ERROR.RECAPTCHA", "error"));
                return false;

              default:
                return false;
            }
          }, result);

          setRequiresPin(false);

          if (setSubmitting) {
            setSubmitting(false);
          }
        }

        return result;
      }
    },
  };
};

export default useSubmitRedeem;

/* @flow */

import type { Brand } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", brand: string }
  | { state: "LOADED", data: Brand }
  | { state: "ERROR", error: string };

export type BrandResponse = Response<typeof BRAND_RESPONSE, ?Brand>;

export type BrandRequest = {
  tag: typeof BRAND_REQUEST,
  brand: string,
};

export const BRAND_RESPONSE: "response/brand" = "response/brand";
export const BRAND_REQUEST: "request/brand" = "request/brand";

export const BrandModel: Model<Data, { brand: string }, BrandRequest | BrandResponse> = {
  id: "brand",
  init: ({ brand }) =>
    updateData(
      { state: "LOADING", brand },
      { tag: BRAND_REQUEST, brand }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case BRAND_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

/* @flow */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

const brands = {
  sv: [
    { img: "akademibokhandeln", title: "Akademibokhandeln", url: "/akademibokhandeln-retain24" },
    { img: "breadnboxers", title: "Bread & Boxers", url: "/bread-boxers" },
    { img: "tv4-play", title: "TV4 Play", url: "/tv4-play-utan-reklam" },
    { img: "elgiganten", title: "Elgiganten", url: "/presentkort-elgiganten" },
    { img: "cervera", title: "Cervera", url: "/cervera" },
    { img: "filmstaden", title: "Filmstaden", url: "/kulturkortet-5252" },
    { img: "granngarden", title: "Granngården", url: "/granngarden-presentkort" },
    { img: "greatdays", title: "Great Days", url: "/greatdays" },
    { img: "ahlens", title: "Åhlens", url: "/ahlens" },
    { img: "mcd", title: "McDonald's", url: "/mcdonald-s" },
    { img: "sf-anytime", title: "SF Anytime", url: "/sf-anytime-rent-buy-film" },
    { img: "royaldesign", title: "RoyalDesign", url: "/royaldesign" },
    { img: "restaurangguiden-com", title: "Restaurangguiden", url: "/restaurangguiden" },
    { img: "sverigelotten", title: "Sverigelotten", url: "/sverigelotten" },
    { img: "bokadirekt", title: "Bokadirekt", url: "/bokadirekt" },
    { img: "zalando", title: "Zalando", url: "/zalando-presentkort" },
  ],
  da: [
    { img: "bio_biografbillet_dk", title: "Biografbillet", url: "/cinema" },
    { img: "tivoli", title: "Tivoli", url: "/tivolikort-11-maneder" },
    { img: "vero_moda", title: "Vero Moda", url: "/vero-moda" },
    { img: "jack_jones_black", title: "Jack & jones", url: "/jack-jones" },
    { img: "selected", title: "Selected", url: "/selected" },
    { img: "name_it", title: "Name it", url: "/name-it" },
    { img: "mofibo", title: "Mofibo", url: "/gavekort-mofibo" },
    { img: "reebok", title: "Reebok", url: "/gavekort-reebok" },
    { img: "adidas", title: "Adidas", url: "/gavekort-adidas" },
    { img: "rituals", title: "Rituals", url: "/rituals" },
    { img: "zalando", title: "Zalando", url: "/zalando-dkk" },
  ],
  fi: [
  ],
  nb: [
    { img: "net_on_net", title: "Netonnet", url: "/netonnet" },
    { img: "jernia", title: "Jernia", url: "/jernia" },
    { img: "zalando", title: "Zalando", url: "/zalando-nok" },
    { img: "morris", title: "Morris", url: "/morris" },
    { img: "feetfirst_ren", title: "Feetfirst", url: "/gavekort-feetfirst" },
    { img: "din_sko_platt", title: "Dinsko", url: "/gavekort-dinsko" },
    { img: "skopunkten", title: "Skopunkten", url: "/gavekort-skopunkten" },
    { img: "jack_jones_black", title: "Jack & jones", url: "/jack-jones" },
    { img: "rituals", title: "Rituals", url: "/rituals" },
    { img: "vero_moda", title: "Vero Moda", url: "/vero-moda" },
    { img: "flipp_HQ", title: "Flipp", url: "/gavekort-flipp-3-maneder-299-nok" },
  ],
};

const PopularBrands = (): React$Node => {
  const { info: { locale } } = useContext(StoreInfoContext);
  const t = useTranslate();

  if (brands[locale ? locale.slice(0, 2) : "sv"].length === 0) {
    return null;
  }

  return (
    <>
      <h2 className={styles.heading}>{t("HOMEVIEW.POPULAR_BRANDS")}</h2>
      <div className={styles.brands}>
        {brands[locale ? locale.slice(0, 2) : "sv"].map(x => (
          <div key={x.title} className={styles.brand}>
            <Link to={x.url}><img src={`/assets/redeem/${x.img}.svg`} alt={x.title} /></Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default PopularBrands;

/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { BrandRequest, BrandResponse } from "state/brand";

import { BRAND_REQUEST, BRAND_RESPONSE } from "state/brand";
import { brand } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: (msg: BrandRequest, path) => {
      return client(brand, { value: msg.brand }, { cache: true })
        .then(data => storage.replyMessage(({
          tag: BRAND_RESPONSE,
          data: data.productsBy.manufacturer,
        }: BrandResponse), path));
    },
    subscribe: { [BRAND_REQUEST]: true },
  });
};

export default registerClient;

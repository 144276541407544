/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { OrderRequest, OrderResponse } from "state/order";

import { syncQuote } from "@crossroads/shop-state/quote";
import { ORDER_REQUEST, ORDER_RESPONSE } from "state/order";
import {
  lastOrder as lastOrderQuery,
  quote as quoteQuery,
} from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: OrderRequest, path) => {
      const queries = [client(lastOrderQuery), msg.loadQuote ? client(quoteQuery) : undefined];

      const response = await Promise.all(queries);

      if (msg.loadQuote && response[1]) {
        const { quote } = response[1];

        storage.broadcastMessage(syncQuote(quote));
      }

      storage.replyMessage(({
        tag: ORDER_RESPONSE,
        data: response[0].lastOrder,
      }: OrderResponse), path);
    },
    subscribe: { [ORDER_REQUEST]: true },
  });
};

export default registerClient;

/* @flow */

import React from "react";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import styles from "./styles.scss";
import cn from "classnames";
import BalanceIcon from "icons/balance.svg";
import { formatDate } from "helpers/utils";

type Props = {
  className?: string,
};

const CustomerBalance = ({ className = "" }: Props): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();

  const quoteData = useData(QuoteData);
  const quote = quoteData.data || null;

  if (!quote) {
    return null;
  }

  const { retain24validation, subTotal, discountTotal } = quote;

  const balance = retain24validation ? retain24validation.value : 0;
  const voucherValue = retain24validation ? retain24validation.value : 0;
  const expiresDate = retain24validation ? retain24validation.expires : "";
  const afterPurchase = Math.max(voucherValue - (subTotal.incVat - Math.abs(discountTotal)), 0);

  if (!balance) {
    return null;
  }

  return (
    <div className={cn(
      styles.item,
      styles.block,
      className
    )}
    >
      <div className={styles.left}>
        <BalanceIcon />
      </div>
      <div className={styles.right}>
        <p className={styles.balance}>
          <span>{t("CART.YOUR_BALANCE")}</span>
          <span>{formatPrice(balance)}</span>
        </p>
        <p className={styles.afterPurchase}>
          <span>{t("CART.AFTER_PURCHASE")}</span>
          <span>{formatPrice(afterPurchase)}</span>
        </p>
        <p className={styles.afterPurchase}>
          <span>{t("CART.VALID_TO")}</span>
          <span>{formatDate(expiresDate)}</span>
        </p>
      </div>
    </div>
  );
};

export default CustomerBalance;

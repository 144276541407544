/* @flow */

import { useEffect, useState } from "react";
import { throttle } from "diskho";

const useBrowserDimensions = (): { height: number, width: number } => {
  const [width, setWidth] = useState<number>(process.browser ? window.innerWidth : 0);
  const [height, setHeight] = useState<number>(process.browser ? window.innerHeight : 0);

  useEffect(() => {
    if (process.browser) {
      const handleResize = throttle(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }, 1000);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [width, height]);

  return {
    width,
    height,
  };
};

export default useBrowserDimensions;

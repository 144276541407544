/* @flow */

import { useEffect } from "react";
import { useBrowser } from "@awardit/react-use-browser";
import useCookie from "helpers/use-cookie";
import { useViewMode } from "context/view-mode";

const useShowLanguageSwitcher = (): any => {
  const isBrowser = useBrowser();
  const [viewMode, setViewMode] = useViewMode();

  const cookieOpts = { domain: `.${typeof window !== "undefined" ? window.location.host : ""}`, expires: 3650 };
  const [
    switcherShown,
    setSwitcherShown,
    isRead,
  ] = useCookie("ls_shown", "false", cookieOpts);

  useEffect(() => {
    if (isBrowser && isRead && switcherShown !== "true" && window.showLanguageSwitcher) {
      setViewMode("language-switcher");
    }
  }, [isBrowser, isRead, switcherShown, setViewMode]);

  const setOpen = (x: boolean) => {
    if (!x) {
      setSwitcherShown("true");
    }

    setViewMode(x ? "language-switcher" : "normal");
  };

  return {
    open: viewMode === "language-switcher",
    setOpen,
  };
};

export default useShowLanguageSwitcher;
